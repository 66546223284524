import React, { useEffect, useState, useMemo } from "react";
import { Dropdown, Image, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SiteSettings from "../../constants/SiteSettings";
import routes from "../../helper/routes";
import Loader from "../Loader";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest } from "../../helper/api";

export const Header = ({ loader }) => {
  const route = useLocation();
  const getAdminRoutesChild = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show === "yes") {
        if (prop?.subMenu) {
          let match = false;
          prop?.subMenu.map((prop) => {
            if (route.pathname === prop.path) {
              match = true;
            }
          });
          return (
            <NavDropdown
              key={key}
              renderMenuOnMount
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
              // active={match}
            >
              {getAdminRoutesChild(prop.subMenu)}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link
                className={`nav-link ${
                  route.pathname === prop.path ? "active" : ""
                }`}
                to={prop.path}
              >
                {prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };
  const getAdminRoutes = (routes, user) => {
    return routes.map((prop, key) => {
      if (
        prop.show === "yes" &&
        prop?.layout === "ADMIN" &&
        user !== null &&
        (user.permissions.includes(prop?.name) ||
          user.permissions.includes("Super Admin"))
      ) {
        if (prop?.subMenu) {
          let match = false;
          prop?.subMenu.map((prop) => {
            if (route.pathname === prop.path) {
              match = true;
            }
          });

          return match ? (
            <Dropdown
              key={key}
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
              autoClose={false}
              show={match ? true : ""}
            >
              <Dropdown.Toggle
                className={`nav-link dropdown-toggle ${match ? "active" : ""}`}
                type="button"
                id={`basic-nav-dropdown-${key}`}
                as="a"
              >
                {prop.name}
              </Dropdown.Toggle>
              <Dropdown.Menu renderOnMount as="ul">
                {getAdminRoutesChild(prop.subMenu)}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Dropdown
              key={key}
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
              autoClose={false}
            >
              <Dropdown.Toggle
                className={`nav-link dropdown-toggle ${match ? "active" : ""}`}
                type="button"
                id={`basic-nav-dropdown-${key}`}
                as="a"
              >
                {prop.name}
              </Dropdown.Toggle>
              <Dropdown.Menu renderOnMount as="ul">
                {getAdminRoutesChild(prop.subMenu)}
              </Dropdown.Menu>
            </Dropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link
                className={`nav-link ${
                  route.pathname === prop.path ? "active" : ""
                }`}
                to={prop.path}
              >
                {prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };
  const [user, setUser] = useState(null); // Maintain the user state
  const [isLoading, setIsLoading] = useState(true); // Maintain the loading state

  const getUser = async () => {
    try {
      const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
      if (storedUser?.username) {
        const { result } = await getRequest(
          `/api/secure/user/profile/${storedUser?.username}`
        );
        if (result?.status === 200) {
          setUser(result?.data?.user);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const menuItems = useMemo(
    () => getAdminRoutes(routes, user),
    [routes, user, route.pathname]
  );

  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <div
      className="sidebar"
      style={{
        display: loader ? "none" : "flex",
      }}
    >
      <div className="logo-details">
        <Link to="/">
          <Image fluid src={SiteSettings.SITE_LOGO} alt="Site Logo" />
        </Link>
      </div>
      <Nav id="main_menu" as="ul" className="me-auto flex-column">
        {menuItems}
      </Nav>
      <p className="copyright_para">{SiteSettings.Copyright_text}</p>
    </div>
  );
};
