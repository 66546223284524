import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { getRequest, putRequest, uploadURL } from "./../../../helper/api";
import Layout from "./../../../layouts/admin/Layout";

import { toast } from "react-toastify";
import EditorWithMentionsAndHashtags from "../../../components/Editor/EditorWithMentionsAndHashtags";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Loader from "./../../../components/Loader";

const Advertisement = () => {
  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [description, setDescription] = useState("");

  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProfileImage(imageList[addUpdateIndex].file);
  };
  const updatePageHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pageName", "Advertisement");
    formData.append("content", description);
    formData.append("featuredImage", profileImage);
    console.log("formData", Object.fromEntries(formData));
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        "/api/secure/page/update-advertisement",
        token,
        formData
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Advertisement Page Updated", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update Page APi error", error.message);
    }
  };
  const getPageByName = async (pageName) => {
    try {
      setLoading(true);
      const { result, error } = await getRequest(
        "/api/secure/page/advertisement",
        "",
        {
          pageName,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { content, featuredImage } = result?.data?.page;
        setDescription(content);
        if (featuredImage || featuredImage !== "" || featuredImage !== null) {
          setImages([
            {
              data_url: uploadURL + featuredImage,
            },
          ]);
        }
      } else {
        toast.error("Something went wrong", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Get Page APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (description) => {
    setDescription(description);
  };
  useEffect(() => {
    getPageByName("Advertisement");
  }, []);

  return (
    <Layout>
      <section className="py-5">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updatePageHandler}
            >
              <Row>
                <Col sm={12} md={9}>
                  <Form.Label>
                    <b>Advertisement Content</b>
                  </Form.Label>
                  <EditorWithMentionsAndHashtags
                    initialContent={description}
                    placeholder={`Description of your Challenge. A few sentences is usually recommended.`}
                    onContentChange={handleDescriptionChange}
                    isMentionAndHashtag={false}
                    editorController="quill-toolbar-1"
                  />
                </Col>
                <Col sm={12} md={3} className="text-center">
                  <Form.Label>
                    <b>Featured Image</b>
                  </Form.Label>
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                    maxFileSize={1000000}
                    acceptType={["jpeg", "jpg", "gif", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper mx-auto mb-5">
                          <button
                            type="button"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            {/* <i className="fal fa-plus"></i> */}
                          </button>
                          {imageList
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img src={image["data_url"]} alt="" />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <FontAwesomeIcon icon={faPencilAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                        {errors && (
                          <div
                            id="imageUploadingErrors"
                            className="text-center"
                          >
                            {errors.maxFileSize && (
                              <Alert className="my-1" variant="danger">
                                Selected file size exceed 1MB
                              </Alert>
                            )}
                            {errors.acceptType && (
                              <Alert className="my-1" variant="danger">
                                Your selected file type is not allow
                              </Alert>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader loading={isLoading} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Advertisement;
