import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getRequest, putRequest } from "./../../../helper/api";
import Layout from "./../../../layouts/admin/Layout";

import { toast } from "react-toastify";
import EditorWithMentionsAndHashtags from "../../../components/Editor/EditorWithMentionsAndHashtags";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";

const Terms = () => {
  const [isLoading, setLoading] = useState(true);
  const [description, setDescription] = useState("");

  const updatePageHandler = async (e) => {
    e.preventDefault();
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        "/api/secure/page/update-terms",
        token,
        {
          pageName: "Terms & Conditions",
          content: description,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Terms & Conditions Page Updated", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Update Page APi error", error.message);
    }
  };
  const getPageByName = async (pageName) => {
    try {
      setLoading(true);
      const { result, error } = await getRequest("/api/secure/page/terms", "", {
        pageName,
      });
      if (result?.status === 200 || result?.status === 201) {
        const { content } = result?.data?.page;
        setDescription(content);
      } else {
        toast.error("Something went wrong", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Get Page APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionChange = (description) => {
    setDescription(description);
  };
  useEffect(() => {
    getPageByName("Terms & Conditions");
  }, []);

  return (
    <Layout>
      <section className="py-5">
        <Container>
          {!isLoading ? (
            <Form
              className="form_add_user "
              encType="multipart/form-data"
              method="post"
              onSubmit={updatePageHandler}
            >
              <Row>
                <Col sm={12}>
                  <Form.Label>
                    <b>Terms & Conditions Content</b>
                  </Form.Label>
                  <EditorWithMentionsAndHashtags
                    initialContent={description}
                    placeholder={`Description of your Challenge. A few sentences is usually recommended.`}
                    onContentChange={handleDescriptionChange}
                    isMentionAndHashtag={false}
                    editorController="quill-toolbar-1"
                  />
                </Col>
                <Col xl={12} sm={12} className="text-center mt-4">
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Loader loading={isLoading} />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default Terms;
