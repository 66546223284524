import React, { useEffect, useState } from "react";
import AdminImages from "./../../constants/admin/images";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { uploadURL } from "./../../helper/api";
import { Header } from "./../../components/admin/Header";
import { Footer } from "./../../components/admin/Footer";
import Loader from "../../components/Loader";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "../../helper/helper";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const route = useLocation();
  const [PageLoader, setPageLoader] = useState(false);
  const [Admin, setAdmin] = useState(null);

  const handleToggler = () => {
    document.querySelector(".sidebar")?.classList.toggle("active");
    document.querySelector("#main_content")?.classList.toggle("active");
  };

  useEffect(() => {
    if (
      !getItemFromLocalStorage("TOKEN") ||
      getItemFromLocalStorage("ROLE") !== "admin"
    ) {
      navigate("/login");
    } else {
      const adminData = JSON.parse(getItemFromLocalStorage("USER"));
      setAdmin(adminData);
    }
  }, [navigate]);

  useEffect(() => {
    const checkWindowWidth = () => {
      const isSmallScreen = window.innerWidth <= 1150;
      const sidebar = document.querySelector(".sidebar");
      const mainContent = document.querySelector("#main_content");
      if (isSmallScreen) {
        sidebar?.classList.add("active");
        mainContent?.classList.add("active");
      } else {
        sidebar?.classList.remove("active");
        mainContent?.classList.remove("active");
      }
    };

    checkWindowWidth();
    window.addEventListener("resize", checkWindowWidth);

    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);

  return (
    <>
      <Header loader={PageLoader} />
      <section
        className="home-section dashboard"
        id="main_content"
        style={{
          display: PageLoader ? "none" : "block",
        }}
      >
        <nav>
          <div className="sidebar-button">
            <button className="btn p-0" onClick={handleToggler}>
                <img
                  src={AdminImages.toggler}
                  alt=""
                  className="sidebarBtn img-fluid"
                />
            </button>
            <span className="dashboard">
                {route.pathname === "/dashboard" ? (
                  "Dashboard"
                ) : route.pathname === "/AboutUs" ? (
                  <Link target="_BLANK" to="/about-us">
                    ABOUT US
                  </Link>
                ) : route.pathname === "/faqsManagement" ? (
                  <Link target="_BLANK" to="/faqs">
                    FAQs
                  </Link>
                ) : route.pathname === "/termsEdit" ? (
                  <Link target="_BLANK" to="/terms-and-conditions">
                    Terms & Conditions
                  </Link>
                ) : route.pathname === "/privacyEdit" ? (
                  <Link target="_BLANK" to="/privacy-policy">
                    Privacy & Policy
                  </Link>
                ) : route.pathname === "/teams" ? (
                  <Link target="_BLANK" to="/team">
                    Team
                  </Link>
                ) : route.pathname === "/AdvertisementManagement" ? (
                  <Link target="_BLANK" to="/advertisement">
                    Advertisement
                  </Link>
                ) : route.pathname === "/settings" ? (
                  "Settings"
                ) : route.pathname === "/Languages" ? (
                  "Languages List"
                ) : route.pathname === "/addLanguage" ? (
                  "Create a new Language"
                ) : route.pathname === "/editSingleLanguage" ? (
                  "Edit Language"
                ) : route.pathname === "/Users" ? (
                  "Users List"
                ) : route.pathname === "/addUser" ? (
                  "Create a new User"
                ) : route.pathname === "/editSingleUser" ? (
                  "Edit User"
                ) : route.pathname === "/blockUsers" ? (
                  "Block Users"
                ) : route.pathname === "/Sliders" ? (
                  "Sliders List"
                ) : route.pathname === "/addSlider" ? (
                  "Create a new Slider"
                ) : route.pathname === "/editSlider" ? (
                  "Edit Slider"
                ) : route.pathname === "/Posts" ? (
                  "Posts List"
                ) : route.pathname === "/addPost" ? (
                  "Create a new Post"
                ) : route.pathname === "/editPost" ? (
                  "Edit Post"
                ) : route.pathname === "/reportPost" ? (
                  "Report Post"
                ) : route.pathname === "/SubSliders" ? (
                  "Sub Sliders List"
                ) : route.pathname === "/addSubSlider" ? (
                  "Create a new Sub Slider"
                ) : route.pathname === "/editSubSlider" ? (
                  "Edit Sub Slider"
                ) : route.pathname === "/topicOfInterests" ? (
                  "Topic Of Interests List"
                ) : route.pathname === "/addTopicOfInterest" ? (
                  "Create a new Topic Of Interest"
                ) : route.pathname === "/editTopicOfInterest" ? (
                  "Edit Topic Of Interest"
                ) : route.pathname === "/Categories" ? (
                  "All Categories"
                ) : route.pathname === "/subCategories" ? (
                  "All Sub Categories"
                ) : route.pathname === "/editCategory" ? (
                  "Edit Category"
                ) : route.pathname === "/editSubCategory" ? (
                  "Edit Sub Category"
                ) : route.pathname === "/addSubCategory" ? (
                  "Create Sub Category"
                ) : route.pathname === "/addCategory" ? (
                  "Create Category"
                ) : route.pathname === "/Challenges" ? (
                  "All Challenges"
                ) : route.pathname === "/addChallenge" ? (
                  "Create Challenge"
                ) : route.pathname === "/EditChallenge" ? (
                  "Edit Challenge"
                ) : route.pathname === "/Emails" ? (
                  "All Email Notifications"
                ) : route.pathname === "/SendEmailNotification" ? (
                  "Send Email Notification"
                ) : route.pathname === "/EditEmailNotification" ? (
                  "Edit Email Notification"
                ) : route.pathname === "/editProduct" ? (
                  "Edit Product"
                ) : route.pathname === "/Products" ? (
                  "All Products"
                ) : (
                  ""
                )}
            </span>
          </div>
          {Admin && (
            <Dropdown id="admin_profile" className="profile-details">
              <Dropdown.Toggle id="dropdown-basic">
                  {Admin.profileImage && (
                    <img src={`${uploadURL}${Admin.profileImage}`} alt="" />
                  )}
                  <span className="admin_name">
                    {Admin.firstName} {Admin?.lastName}
                  </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="li">
                  <Link to={"/dashboard"}>Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Item as="li">
                  <Link to={"/account"}>Account</Link>
                </Dropdown.Item>
                <Dropdown.Item as="li">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      removeItemFromLocalStorage("TOKEN");
                      removeItemFromLocalStorage("ROLE");
                      removeItemFromLocalStorage("USER");
                      removeItemFromLocalStorage("REFERRAL_SETTINGS");
                      removeItemFromLocalStorage("LANGUAGE_SETTINGS");
                      removeItemFromLocalStorage("SITE_LOGO");
                      removeItemFromLocalStorage("SITE_SETTINGS");
                      removeItemFromLocalStorage("Products");
                      removeItemFromLocalStorage("POPUP_SETTINGS");
                      navigate("/", { replace: true });
                    }}
                  >
                    Sign Out
                  </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </nav>
        <main>{children}</main>
      </section>
      <Footer />
    </>
  );
};

export default Layout;
