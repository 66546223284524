import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../components/Loader";
import PublicImages from "../../constants/public/images";
import { getRequest, uploadURL } from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";
import Layout from "../../layouts/public/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { Autoplay } from "swiper/modules";
moment.tz.setDefault("Asia/Kolkata");
const ChallengesArchive = () => {
  const [blogChallengesLoading, setBlogChallengesLoading] = useState(true);
  const [storyChallengesLoading, setStoryChallengesLoading] = useState(true);
  const [imageChallengesLoading, setImageChallengesLoading] = useState(true);
  const [videoChallengesLoading, setVideoChallengesLoading] = useState(true);
  const [audioChallengesLoading, setAudioChallengesLoading] = useState(true);
  const [blogChallenges, setBlogChallenges] = useState([]);
  const [storyChallenges, setStoryChallenges] = useState([]);
  const [imageChallenges, setImageChallenges] = useState([]);
  const [videoChallenges, setVideoChallenges] = useState([]);
  const [audioChallenges, setAudioChallenges] = useState([]);

  const loadBlogChallenges = async () => {
    try {
      setBlogChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        token,
        {
          productSlug: "blog",
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setBlogChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBlogChallengesLoading(false);
    }
  };
  const loadStoryChallenges = async () => {
    try {
      setStoryChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        token,
        {
          productSlug: "short-stories",
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setStoryChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setStoryChallengesLoading(false);
    }
  };
  const loadImageChallenges = async () => {
    try {
      setImageChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        token,
        {
          productSlug: "image-corner",
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setImageChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setImageChallengesLoading(false);
    }
  };
  const loadVideoChallenges = async () => {
    try {
      setVideoChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        token,
        {
          productSlug: "videos",
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setVideoChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setVideoChallengesLoading(false);
    }
  };
  const loadAudioChallenges = async () => {
    try {
      setAudioChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        token,
        {
          productSlug: "audio-station",
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setAudioChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAudioChallengesLoading(false);
    }
  };
  useEffect(() => {
    loadBlogChallenges();
    loadStoryChallenges();
    loadImageChallenges();
    loadVideoChallenges();
    loadAudioChallenges();
  }, []);
  return (
    <Layout>
      {blogChallengesLoading ? (
        <Loader loading={blogChallengesLoading} />
      ) : (
        <>
          <section className="popular_sec" id="blogChallenges">
            <Container>
              <h1>
                <Link
                  reloadDocument={true}
                  to={`/blog/challenges/`}
                  className="text-decoration-none text-dark d-block  w-100"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <img src={PublicImages.pages} className="img-fluid" alt="" />
                  Blogs Challenges
                </Link>
              </h1>
              {blogChallenges.length > 0 ? (
                <Swiper
                  navigation={true} // Show navigation arrows
                  spaceBetween={20} // Space between slides
                  slidesPerView={4} // Number of slides to show
                  loop={true} // Enable loop
                  loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Enable autoplay
                  modules={[Autoplay]}
                  breakpoints={{
                    // For mobile devices
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    // For desktop and larger screens
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {blogChallenges.map(
                    (
                      { slug, photo, name, challengeEndDate, productId },
                      index
                    ) => (
                      <SwiperSlide key={index}>
                        <Card className="card_style_2 ">
                          <Link
                            reloadDocument={true}
                            to={`/${productId?.slug}/challenges/${slug}`}
                            className="text-decoration-none text-dark d-block  w-100"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={uploadURL + photo}
                              className="img-fluid w-100 challengeImage"
                              wrapperClassName="d-block"
                              alt=""
                            />
                            {/* {challengeEndDate &&
                              moment(new Date()).isBefore(challengeEndDate) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )} */}
                            {challengeEndDate &&
                              moment(challengeEndDate).isSameOrAfter(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}

                            <Card.Body className="text-center">
                              <Card.Title>
                                <p>{name}</p>
                              </Card.Title>
                            </Card.Body>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              ) : (
                <Row>
                  <Col className="text-center" xs={12}>
                    <p>No Blog Challeges Added Yet!</p>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      )}

      {storyChallengesLoading ? (
        <Loader loading={storyChallengesLoading} />
      ) : (
        <>
          <section className="popular_sec bg-white" id="storyChallenges">
            <Container>
              <h1>
                <Link
                  reloadDocument={true}
                  to={`/short-stories/challenges/`}
                  className="text-decoration-none text-dark d-block  w-100"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <img src={PublicImages.pages} className="img-fluid" alt="" />
                  Short Story Challenges
                </Link>
              </h1>
              {storyChallenges.length > 0 ? (
                <Swiper
                  navigation={true} // Show navigation arrows
                  spaceBetween={20} // Space between slides
                  slidesPerView={4} // Number of slides to show
                  loop={true} // Enable loop
                  loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Enable autoplay
                  modules={[Autoplay]}
                  breakpoints={{
                    // For mobile devices
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    // For desktop and larger screens
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {storyChallenges.map(
                    (
                      { slug, photo, name, challengeEndDate, productId },
                      index
                    ) => (
                      <SwiperSlide key={index}>
                        <Card className="card_style_2 ">
                          <Link
                            reloadDocument={true}
                            to={`/${productId?.slug}/challenges/${slug}`}
                            className="text-decoration-none text-dark d-block  w-100"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={uploadURL + photo}
                              className="img-fluid w-100 challengeImage"
                              wrapperClassName="d-block"
                              alt=""
                            />
                            {/* {challengeEndDate &&
                              moment(new Date()).isBefore(challengeEndDate) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )} */}
                            {challengeEndDate &&
                              moment(challengeEndDate).isSameOrAfter(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}

                            <Card.Body className="text-center">
                              <Card.Title>
                                <p>{name}</p>
                              </Card.Title>
                            </Card.Body>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              ) : (
                <Row>
                  <Col className="text-center" xs={12}>
                    <p>No Short Story Challeges Added Yet!</p>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      )}

      {imageChallengesLoading ? (
        <Loader loading={imageChallengesLoading} />
      ) : (
        <>
          <section className="popular_sec" id="imageChallenges">
            <Container>
              <h1>
                <Link
                  reloadDocument={true}
                  to={`/image-corner/challenges/`}
                  className="text-decoration-none text-dark d-block  w-100"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <img src={PublicImages.pages} className="img-fluid" alt="" />
                  Image Challenges
                </Link>
              </h1>
              {imageChallenges.length > 0 ? (
                <Swiper
                  navigation={true} // Show navigation arrows
                  spaceBetween={20} // Space between slides
                  slidesPerView={4} // Number of slides to show
                  loop={true} // Enable loop
                  loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Enable autoplay
                  modules={[Autoplay]}
                  breakpoints={{
                    // For mobile devices
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    // For desktop and larger screens
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {imageChallenges.map(
                    (
                      { slug, photo, name, challengeEndDate, productId },
                      index
                    ) => (
                      <SwiperSlide key={index}>
                        <Card className="card_style_2 ">
                          <Link
                            reloadDocument={true}
                            to={`/${productId?.slug}/challenges/${slug}`}
                            className="text-decoration-none text-dark d-block  w-100"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={uploadURL + photo}
                              className="img-fluid w-100 challengeImage"
                              wrapperClassName="d-block"
                              alt=""
                            />
                            {/* {challengeEndDate &&
                              moment(new Date()).isBefore(challengeEndDate) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )} */}
                            {challengeEndDate &&
                              moment(challengeEndDate).isSameOrAfter(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}

                            <Card.Body className="text-center">
                              <Card.Title>
                                <p>{name}</p>
                              </Card.Title>
                            </Card.Body>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              ) : (
                <Row>
                  <Col className="text-center" xs={12}>
                    <p>No Image Challeges Added Yet!</p>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      )}

      {videoChallengesLoading ? (
        <Loader loading={videoChallengesLoading} />
      ) : (
        <>
          <section className="popular_sec  bg-white" id="videoChallenges">
            <Container>
              <h1>
                <Link
                  reloadDocument={true}
                  to={`/videos/challenges/`}
                  className="text-decoration-none text-dark d-block  w-100"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <img src={PublicImages.pages} className="img-fluid" alt="" />
                  Video Challenges
                </Link>
              </h1>
              {videoChallenges.length > 0 ? (
                <Swiper
                  navigation={true} // Show navigation arrows
                  spaceBetween={20} // Space between slides
                  slidesPerView={4} // Number of slides to show
                  loop={true} // Enable loop
                  loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Enable autoplay
                  modules={[Autoplay]}
                  breakpoints={{
                    // For mobile devices
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    // For desktop and larger screens
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {videoChallenges.map(
                    (
                      { slug, photo, name, challengeEndDate, productId },
                      index
                    ) => (
                      <SwiperSlide key={index}>
                        <Card className="card_style_2 ">
                          <Link
                            reloadDocument={true}
                            to={`/${productId?.slug}/challenges/${slug}`}
                            className="text-decoration-none text-dark d-block  w-100"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={uploadURL + photo}
                              className="img-fluid w-100 challengeImage"
                              wrapperClassName="d-block"
                              alt=""
                            />
                            {/* {challengeEndDate &&
                              moment(new Date()).isBefore(challengeEndDate) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )} */}
                            {challengeEndDate &&
                              moment(challengeEndDate).isSameOrAfter(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}

                            <Card.Body className="text-center">
                              <Card.Title>
                                <p>{name}</p>
                              </Card.Title>
                            </Card.Body>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              ) : (
                <Row>
                  <Col className="text-center" xs={12}>
                    <p>No Challeges Added Yet!</p>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      )}

      {audioChallengesLoading ? (
        <Loader loading={audioChallengesLoading} />
      ) : (
        <>
          <section className="popular_sec" id="audioChallenges">
            <Container>
              <h1>
                <Link
                  reloadDocument={true}
                  to={`/audio-station/challenges/`}
                  className="text-decoration-none text-dark d-block  w-100"
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <img src={PublicImages.pages} className="img-fluid" alt="" />
                  Audio Challenges
                </Link>
              </h1>
              {audioChallenges.length > 0 ? (
                <Swiper
                  navigation={true} // Show navigation arrows
                  spaceBetween={20} // Space between slides
                  slidesPerView={4} // Number of slides to show
                  loop={true} // Enable loop
                  loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Enable autoplay
                  modules={[Autoplay]}
                  breakpoints={{
                    // For mobile devices
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    // For desktop and larger screens
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {audioChallenges.map(
                    (
                      { slug, photo, name, challengeEndDate, productId },
                      index
                    ) => (
                      <SwiperSlide key={index}>
                        <Card className="card_style_2 ">
                          <Link
                            reloadDocument={true}
                            to={`/${productId?.slug}/challenges/${slug}`}
                            className="text-decoration-none text-dark d-block  w-100"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={uploadURL + photo}
                              className="img-fluid w-100 challengeImage"
                              wrapperClassName="d-block"
                              alt=""
                            />
                            {/* {challengeEndDate &&
                              moment(new Date()).isBefore(challengeEndDate) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )} */}
                            {challengeEndDate &&
                              moment(challengeEndDate).isSameOrAfter(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}

                            <Card.Body className="text-center">
                              <Card.Title>
                                <p>{name}</p>
                              </Card.Title>
                            </Card.Body>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              ) : (
                <Row>
                  <Col className="text-center" xs={12}>
                    <p>No Audio Challeges Added Yet!</p>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      )}
    </Layout>
  );
};

export default ChallengesArchive;
